.mainbody{
    height: 100vh;
    margin: 0px;
}

/* forestbridge.jpg */

.bgimg {
  background: url('../../public/img/forestbridge.jpg');
  height: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
}

.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

hr {
  margin: auto;
  width: 40%;
}


@keyframes animateText {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  } */
  
  .animated-text {
    font-size: 40px;
    animation: animateText 2s ease-in-out infinite;
  }
  